import { useTranslation } from "react-i18next";
import { Form, NumberInput, TextInput } from "@wfp/react";
import { IBasketItem } from "../../types/basket";
import { IBasketItemError } from "../../models/validate-basket-item";

interface Props {
  error?: IBasketItemError;
  item: IBasketItem;
  onChange: (item: IBasketItem) => void;
}

function BasketItemForm(props: Props) {
  const { t } = useTranslation();

  return (
      <Form>
          <div className="grid grid-cols-1 gap-y-5 mt-5 mb-5">
              <TextInput
                  invalid={!!props.error?.nameAr}
                  invalidText={t(props.error?.nameAr?.toString() || '')}
                  labelText={t('nameAr')}
                  name='nameAr'
                  onChange={(e: any) =>
                      props.onChange({...props.item, nameAr: e.target.value})
                  }
                  value={props.item.nameAr || ''}
              />
              <TextInput
                  invalid={!!props.error?.nameEn}
                  invalidText={t(props.error?.nameEn?.toString() || '')}
                  labelText={t('nameEn')}
                  name='nameEn'
                  onChange={(e: any) =>
                      props.onChange({...props.item, nameEn: e.target.value})
                  }
                  value={props.item.nameEn || ''}
              />
              <NumberInput
                  invalid={!!props.error?.quantity}
                  invalidText={t(props.error?.quantity?.toString() || '')}
                  labelText={t('Quantity')!}
                  name='quantity'
                  onChange={(e: any) =>
                      props.onChange({...props.item, quantity: e.target.value})
                  }
                  value={props.item.quantity || 1}
                  />
          </div>
      </Form>
);
}

export default BasketItemForm;
