export interface IUser {
    pk: number;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    address: string;
    role: Role;
}

export enum Role {
    Beneficiary = 'beneficiary',
    WFPManager = 'wfpmanager',
    WFPStaff = 'wfpstaff',
    None = '',
}
