import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  BlockNotification,
  Breadcrumb,
  BreadcrumbHome,
  BreadcrumbItem,
  Button,
  Modal,
  Text,
} from '@wfp/react';
import { AddCircle, Delete } from "@wfp/icons-react";
import { PaginationParams, ServerError } from "../../types/commons";
import { IBasketItem } from "../../types/basket";
import {
  IBasketItemError,
  validateBasketItem,
} from "../../models/validate-basket-item";
import { disableBasket } from "../../services/basket";
import { deleteBasketItem, saveBasketItem } from "../../services/basket-item";
import { useGetBasket } from "../../hooks/basket";
import {
  useGetBasketItems,
  useInvalidateBasketItems,
} from "../../hooks/basket-item";
import BasketItemForm from "../basket-item/basket-item-form";
import BasketItemTable from "../basket-item/basket-item-table";
import { LoadingCenter } from "../commons/loading-center";

function BasketDetail() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    pageNumber: 1,
  });
  const [sorting, setSorting] = useState<string[] | undefined>(["id"]);
  const [selectedBasketItem, setSelectedBasketItem] = useState<IBasketItem>();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [basketItemError, setBasketItemError] = useState<IBasketItemError>();
  const [serverError, setServerError] = useState<ServerError>();

  const {
    data: basket,
    isLoading: isLoadingBasket,
    isError: isErrorBasket,
  } = useGetBasket(Number(id));
  const {
    data: itemsPage,
    isLoading: isLoadingItems,
    isError: isErrorItems,
  } = useGetBasketItems(Number(id), paginationParams, sorting);
  const { mutate: updateItemsPage } = useInvalidateBasketItems(Number(id));

  const handleItemAdd = () => {
    if (!!basket) {
      setBasketItemError(undefined);
      setSelectedBasketItem({ basket: basket!.id, quantity: 1 } as IBasketItem);
      setOpenModal(true);
    }
  };

  const handleItemEdit = (item: IBasketItem) => {
    setSelectedBasketItem(item);
    setOpenModal(true);
  }

  const handleItemSaveConfirm = () => {
    if (!!selectedBasketItem) {
      validateBasketItem(selectedBasketItem)
        .then((basket) =>
          saveBasketItem(basket)
            .then((res) => {
              setOpenModal(false);
              setBasketItemError(undefined);
              setSelectedBasketItem(res);
              updateItemsPage();
            })
            .catch((e) => setBasketItemError(e as IBasketItemError))
        )
        .catch((e) => setBasketItemError(e));
    }
  };

  const handleItemSaveClose = () => {
    setOpenModal(false);
    setBasketItemError(undefined);
    setSelectedBasketItem(undefined);
  };

  const handleItemDelete = (item: IBasketItem) => {
    setConfirmDelete(true);
    setSelectedBasketItem(item);
    setServerError(undefined);
    setOpenModal(true);
  }
  const handleItemDeleteConfirm = () => {
    if (!!selectedBasketItem && confirmDelete) {
      deleteBasketItem(selectedBasketItem)
        .then(() => {
          setOpenModal(false);
          setConfirmDelete(false);
          setSelectedBasketItem(undefined);
          setServerError(undefined);
          updateItemsPage();
      }).catch((e) => setServerError(e as ServerError));
    }
  };
  const handleItemDeleteCancel = () => {
    setConfirmDelete(false)
    setSelectedBasketItem(undefined);
    setServerError(undefined);
    setOpenModal(false);
  }

  const handleDeleteBasketClose = () => {
    setOpenModal(false);
  };

  const handleDeleteBasketConfirm = () => {
    if (!!basket) {
      disableBasket(basket!.id)
        .then((res) => {
          setOpenModal(false);
          setServerError(undefined);
          navigate(`/baskets/`);
        })
        .catch((e) => setServerError(e as ServerError));
    }
  };

  const handleDeleteBasket = () => {
    setServerError(undefined);
    setOpenModal(true);
  };

  return (
    <>
      <Breadcrumb className="mt-5">
        <BreadcrumbItem>
          <a href="/">
            <BreadcrumbHome />
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem href="/baskets">{t("basket-list")}</BreadcrumbItem>
        <BreadcrumbItem disableLink>{basket?.name}</BreadcrumbItem>
      </Breadcrumb>
      {isLoadingBasket && <LoadingCenter />}
      {isErrorBasket && <div>Error...</div>}
      {basket && (
        <div className="mt-8">
          <div className="text-justify">
            <div className="flex justify-between">
              <Text kind="story-title" className="text-2xl">
                {t("BasketDetails")}
              </Text>
              <div className="flex gap-2">
                <Button
                  icon={Delete}
                  kind="danger"
                  onClick={handleDeleteBasket}
                >
                  {t("delete")}
                </Button>
                <Button icon={AddCircle} onClick={handleItemAdd}>
                  {t("basket-item-add")}
                </Button>
              </div>
            </div>
            <div className="w-2/5">
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("ID")}: </span>
                <p>{basket.id}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("Name")}: </span>
                <p>{basket.name}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("CreatedOn")}: </span>
                <p>{basket.createdOn}</p>
              </div>
            </div>
          </div>
          <div className="text-left mt-4">
            <Text kind="story-title" className="text-2xl text-justify">
              {t("basket-item-list")}
            </Text>
            {isLoadingItems && <LoadingCenter />}
            {isErrorItems && <div>Error...</div>}
            {!!itemsPage && (<>
            {itemsPage.count === 0 && <BlockNotification
              kind="info"
              title={t("basket-items-missing")}
              subtitle={t("basket-items-missing-description")}
            />}
              <div className="flex-col">
                <BasketItemTable
                  page={itemsPage}
                  onChangePagination={setPaginationParams}
                  onChangeSorting={setSorting}
                  onDeleteRow={handleItemDelete}
                  onEditRow={handleItemEdit}
                />
              </div>
            </>)}
          </div>
        </div>
      )}
      {openModal && !!selectedBasketItem && !selectedBasketItem.id && (
        <Modal
          open={true}
          onRequestClose={handleItemSaveClose}
          onRequestSubmit={handleItemSaveConfirm}
          modalHeading={t("basket-item-add")}
          primaryButtonText={t('add')}
          secondaryButtonText={t('cancel')}
          width="full"
        >
          <BasketItemForm
            error={basketItemError}
            item={selectedBasketItem!}
            onChange={setSelectedBasketItem}
          />
        </Modal>
      )}
      {openModal && !!selectedBasketItem && !!selectedBasketItem.id && !confirmDelete && (
        <Modal
          open={true}
          onRequestClose={handleItemSaveClose}
          onRequestSubmit={handleItemSaveConfirm}
          modalHeading={t("basket-item-edit")}
          primaryButtonText={t('save')}
          secondaryButtonText={t('cancel')}
          width="full"
        >
          <BasketItemForm
            error={basketItemError}
            item={selectedBasketItem!}
            onChange={setSelectedBasketItem}
          />
        </Modal>
      )}
      {openModal && !!selectedBasketItem?.id && confirmDelete && (
        <Modal
          open={true}
          onRequestClose={handleItemDeleteCancel}
          onRequestSubmit={handleItemDeleteConfirm}
          modalHeading={t("basket-item-delete")}
          primaryButtonText={t("Yes")}
          secondaryButtonText={t("No")}
          width="full"
        >
          <Text>{t("confirm-item-delete")}</Text>
          {serverError && (
            <Text kind="error">{serverError?.errors?.toString()}</Text>
          )}
        </Modal>
      )}
      {" "}
      {openModal && !selectedBasketItem && (
        <Modal
          open={true}
          onRequestClose={handleDeleteBasketClose}
          onRequestSubmit={handleDeleteBasketConfirm}
          modalHeading={t("basket-delete")}
          primaryButtonText={t("Yes")}
          secondaryButtonText={t("No")}
          width="full"
        >
          <Text>{t("confirm-basket-delete")}</Text>
          {serverError && (
            <Text kind="error">{serverError?.errors?.toString()}</Text>
          )}
        </Modal>
      )}
    </>
  );
}

export default BasketDetail;
