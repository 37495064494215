import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@wfp/react";
import { IDelivery } from "../../types/delivery";
import { bulkCreateCycle } from "../../services/cycle";
import DeliveryBulkAddForm from "./delivery-bulk-form";
import { useInvalidateCycle } from "../../hooks/cycle";
import { useInvalidateCycleDeliveries } from '../../hooks/delivery';

interface Props {
  item?: IDelivery;
  className?: string;
  cycleId: number;
}

export interface IBulkError {
  baskets?: string[];
  beneficiary?: string[];
  generic?: string[];
}
export default function DeliveryAddEdit(props: Props) {
  const { t } = useTranslation();
  const [baskets, setBaskets] = useState<number[]>([]);
  const [allBeneficiaries, setAllBeneficiaries] = useState<number>(1);
  const [openModal, setOpenModal] = useState(false);
  const [bulkError, setBulkError] = useState<IBulkError>();
  const { mutate: invalidateCycle } = useInvalidateCycle(props.cycleId);
  const { mutate: updateCycleDeliveries} = useInvalidateCycleDeliveries(props.cycleId);

  const handleAddDeliveries = () => {
    setOpenModal(true);
    setBulkError(undefined);
    setBaskets([]);
    setAllBeneficiaries(1);
  };

  const handleAddDeliveriesConfirm = () => {
    let errors = false;
    if (baskets.length === 0) {
      setBulkError({
        ...bulkError,
        baskets: [t("select_at_least_one_basket")],
      });
      errors = true;
    }
    if (allBeneficiaries === 0) {
      setBulkError({
        ...bulkError,
        beneficiary: [t("select_beneficiary")],
      });
      errors = true;
    }
    if (!errors) {
      setBulkError(undefined);
      bulkCreateCycle(props.cycleId, baskets, allBeneficiaries)
        .then((r) => {
          invalidateCycle();
          updateCycleDeliveries();
          setOpenModal(false);
          setBulkError(undefined);
        })
        .catch((e) => {
          setBulkError({
            ...bulkError,
            generic: [e[0]],
          })
        });
    }
  };

  const handleAddDeliveriesCancel = () => {
    setOpenModal(false);
    setBulkError(undefined);
  };

  return (
    <div className={props.className}>
      <Button kind="secondary" onClick={handleAddDeliveries} className="">
        {!!props.item ? t("delivery-edit") : t("delivery-add")}
      </Button>
      <Modal
        open={openModal}
        onRequestClose={handleAddDeliveriesCancel}
        onRequestSubmit={handleAddDeliveriesConfirm}
        modalHeading={t("selectAtLeastOneBasket")}
        modalLabel={!!props.item ? t("delivery-edit") : t("delivery-add")}
        primaryButtonText={!!props.item ? t('edit') : t('add')}
        secondaryButtonText={t('cancel')}
        width="full"
        lazyLoad
        children={
          <DeliveryBulkAddForm
            onChangeBaskets={setBaskets}
            onChangeBeneficiary={setAllBeneficiaries}
            baskets={baskets}
            errors={bulkError}
          />
        }
      />
    </div>
  );
}
