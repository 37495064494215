import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { getUserCurrent, getUsers, getUserDetail } from "../services/user";
import { IUser } from '../types/user';
import { KVPair, ListPage, PaginationParams } from '../types/commons';


export function useOptionalUser(): IUser | undefined {
    const queryClient = useQueryClient();
    const queryResult = useQuery(['user', 'optional'], {
       queryFn: () => queryClient.getQueryData(['user', 'current']) as IUser | undefined
    });
    return queryResult.data;
}

export function useLoggedUser() {
    const queryClient = useQueryClient();
    const userQuery = useQuery(['user', 'current'], () => getUserCurrent(), {
        staleTime: Infinity,  // never reload
        cacheTime: Infinity,  // never reload
        retry: false,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['user', 'optional'] }),
        onError: () => console.log('Failed to fetch user')
    });
    return userQuery.data;
}

export function useInvalidateUser() {
    const queryClient = useQueryClient();
    return useMutation((user: IUser|undefined) => Promise.resolve(), {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['user', 'current'] })
    });
}

export function useGetUsers(
  params: PaginationParams,
  filters?: KVPair[],
  sorting?: string[]
): UseQueryResult<ListPage<IUser>> {
  return useQuery(
    ["user", "list", params, filters, sorting],
    () => getUsers(params, filters, sorting),
    {
      retry: false,
    }
  );
}

export function useInvalidateUsers(
  params: PaginationParams,
  filters?: KVPair[],
  sorting?: string[]
) {
  const queryClient = useQueryClient();
  return useMutation(() => Promise.resolve(), {
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["user", "list", params, filters, sorting] }),
  });
}

export function useGetUser(id: number): UseQueryResult<IUser> {
  return useQuery(["user", id], () => getUserDetail(id), {
    retry: false,
  });
}
