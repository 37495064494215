import { useTranslation } from "react-i18next";
import { useState } from "react";
import { IBeneficiary } from "../../types/beneficiary";
import { IBeneficiaryError } from "../../models/validate-beneficiary";
import { saveBeneficiary } from "../../services/beneficiary";
import { useNavigate } from "react-router-dom";
import { validateBeneficiary } from "../../models/validate-beneficiary";
import { Button, Modal } from "@wfp/react";
import BeneficiaryForm from "./beneficiary-form";
import { useInvalidateBeneficiary } from "../../hooks/beneficiary";

interface Props {
  item?: IBeneficiary;
  className?: string;
}
function BeneficiaryAddEdit(props: Props) {
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<IBeneficiary>();
  const [beneficiaryError, setBeneficiaryError] = useState<IBeneficiaryError>();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const labelText = !!props.item?.id ? t('edit') : t('add');
  const buttonText = !!props.item?.id ? t('save') : t('create');
  const { mutate } = useInvalidateBeneficiary(props.item?.id || 0);

  const handleAddItem = () => {
    if (!!props.item) {
      setSelectedBeneficiary(props.item);
    } else {
      setSelectedBeneficiary({} as IBeneficiary);
    }
    setOpenModal(true);
    setBeneficiaryError(undefined);
  };

  const handleBeneficiaryChange = (
    updatedBeneficiary: IBeneficiary,
    updatedField: "email" | "idmId" | "address" | "isActive"
  ) => {
    setBeneficiaryError({ ...beneficiaryError, [updatedField]: undefined } as IBeneficiaryError);
    setSelectedBeneficiary(updatedBeneficiary);
  };

  const handleAddItemSubmit = () => {
    if (!!selectedBeneficiary) {
      validateBeneficiary(selectedBeneficiary)
        .then((beneficiary) =>
          saveBeneficiary(beneficiary)
            .then((res) => {
              setOpenModal(false);
              setBeneficiaryError(undefined);
              setSelectedBeneficiary(res);
              mutate();
              navigate(`/beneficiaries/${res.id}`);
            })
            .catch((e) => setBeneficiaryError(e as IBeneficiaryError))
        )
        .catch((e) => setBeneficiaryError(e));
    }
  };

  const handleAddItemClose = () => {
    setOpenModal(false);
    setBeneficiaryError(undefined);
    setSelectedBeneficiary(undefined);
  };

  return (
    <div className={props.className}>
      <Button kind="primary" onClick={handleAddItem} className="">
        {labelText + " " + t("Beneficiary")}
      </Button>
      <Modal
        open={openModal}
        onRequestClose={handleAddItemClose}
        onRequestSubmit={handleAddItemSubmit}
        modalHeading={labelText + " " + t("Beneficiary")}
        primaryButtonText={buttonText}
        secondaryButtonText={t('cancel')}
        width="full"
        lazyLoad
        children={
          <BeneficiaryForm
            item={selectedBeneficiary!}
            onChange={handleBeneficiaryChange}
            error={beneficiaryError}
          />
        }
      />
    </div>
  );
}

export default BeneficiaryAddEdit;
