import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { t } from 'i18next';
import { format } from 'date-fns';
import {
  DateRangePicker as WfpDatePicker,
  Form,
  Checkbox,
  Text
} from '@wfp/react';
import { DeliveryState, IDelivery } from '../../types/delivery';
import { useGetBaskets } from '../../hooks/basket';
import { useGetCycle } from '../../hooks/cycle';
import { LoadingCenter } from '../commons/loading-center';

export interface IDeliveryErrorInfo {
  scheduledFrom?: string;
  scheduledTo?: string;
  baskets?: string;
  generic?: string[];
}

interface IDeliveryFormProps {
  item: IDelivery;
  onChange: (value: IDelivery) => void;
  error?: IDeliveryErrorInfo;
}

export function DeliveryForm(props: IDeliveryFormProps) {
  const { data: basketPage, isLoading: isLoadingBasketPage, isError: isErrorBasketPage } = useGetBaskets({ pageNumber: 1 });
  const {
    data: cycle,
  } = useGetCycle(props.item.cycle);

  const [baskets, setBaskets] = useState<number[]>(props.item.baskets);

  const [startDate, setStartDate] = useState<Date | null>(
    props.item?.scheduledFrom ? new Date(props.item.scheduledFrom) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    props.item?.scheduledTo ? new Date(props.item?.scheduledTo) : null
  );
  const formattedStartDate = startDate ? format(startDate, "MM/dd/yyyy") : "";
  const formattedEndDate = endDate ? format(endDate, "MM/dd/yyyy") : "";

  const handleSelectDate = (selectedDate: Date, dateType: "from" | "to") => {
    const date = format(selectedDate, "yyy-MM-dd");
    if (dateType === "from") {
      props.onChange({ ...props.item, scheduledFrom: date });
      setStartDate(selectedDate);
    } else if (dateType === "to") {
      props.onChange({ ...props.item, scheduledTo: date });
      setEndDate(selectedDate);
    }
  };

  const onChangeBasket = (id: number) => {
    const value = baskets.includes(id) ? baskets.filter(item => item !== id) : [...baskets, id];
    setBaskets(value);
    props.onChange({...props.item, baskets: value});
  }

  return (
    <Form>
      <div className="space-y-5">
        {props.item.state === DeliveryState.BOOKED && (
          <WfpDatePicker
            labelText={t("selectDateRange")}
            datePicker={ReactDatePicker}
            startDate={formattedStartDate}
            endDate={formattedEndDate || formattedStartDate}
            fromProps={{
              selected: startDate,
              onSelect: (date: Date) => handleSelectDate(date, "from"),
              error: !!props.error?.scheduledFrom,
              minDate: cycle?.dateFrom && new Date(cycle?.dateFrom),
              maxDate: cycle?.dateTo && new Date(cycle?.dateTo),
            }}
            toProps={{
              selected: endDate,
              onSelect: (date: Date) => handleSelectDate(date, "to"),
              disabled: !startDate,
              minDate: cycle?.dateFrom && new Date(cycle?.dateFrom),
              maxDate: cycle?.dateTo && new Date(cycle?.dateTo),
            }}
          />
        )}
        {props.error?.scheduledFrom && (
          <p className="text-red-500">
            {t(props.error?.scheduledFrom?.toString() || "")}
          </p>
        )}
        {props.error?.scheduledTo && (
          <p className="text-red-500">
            {t(props.error?.scheduledTo?.toString() || "")}
          </p>
        )}
        {isLoadingBasketPage && <LoadingCenter />}
        {isErrorBasketPage && <div>{t("Error")}</div>}
        {!!basketPage && (
          <div className="wfp--form-item">
            <Text className="wfp--label">{t("basket-list")}</Text>
            {props.error?.baskets && (
              <p className="text-red-500">
                {t(props.error?.baskets?.toString() || "")}
              </p>
            )}
            <div className="overflow-y-scroll max-h-60 w-full">
              {basketPage.results.map((b, idx) => (
                <div className="" key={idx}>
                  <Checkbox
                    name={b.name}
                    value={b.id}
                    onChange={(e) => onChangeBasket(Number(e.target.value))}
                    labelText={b.name}
                    checked={baskets?.includes(b.id) }
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Form>
  );
}
