import {ServerError} from "../types/commons";
import { IBeneficiary } from "../types/beneficiary";


export interface IBeneficiaryError extends ServerError {
    email: string[] | undefined;
    idmId: string[] | undefined;
    address: string[] | undefined;
}

class BeneficiaryErrorInfo implements IBeneficiaryError {
    email: string[] | undefined;
    idmId: string[] | undefined;
    address: string[] | undefined;
    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}

export function validateBeneficiary(item: IBeneficiary): Promise<IBeneficiary> {
    const error = new BeneficiaryErrorInfo();
    if (!item.email) {
        error.add('email', 'this_field_is_required');
    }
    if (!!item.email && !item.email.includes('@')) {
        error.add('email', 'invalid_email');
    }
    if (!item.idmId) {
        error.add('idmId', 'this_field_is_required');
    }
    if (!item.address) {
        error.add('address', 'this_field_is_required');
    }
    const isValid = Object.values(error).every(f => !f);
    return isValid ? Promise.resolve(item) : Promise.reject(error);
}
