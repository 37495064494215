import {
  Button,
  Modal,
} from "@wfp/react";
import { IDelivery } from "../../types/delivery";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useInvalidateBasketDeliveryList, useInvalidateDeliveries, useInvalidateDelivery } from '../../hooks/delivery';
import { saveDelivery } from "../../services/delivery";
import { IDeliveryErrorInfo, DeliveryForm }  from "./delivery-form";

interface DeliveryEditProps {
  item: IDelivery;
  className?: string;
  onUpdate?: () => void;
}

export default function DeliveryEdit(props: DeliveryEditProps) {
  const { mutate: updateBasketDeliveryList } = useInvalidateBasketDeliveryList(props.item.id)
  const { mutate: updateDelivery} = useInvalidateDelivery(props.item.id)
  const { mutate: invalidateDeliveries } = useInvalidateDeliveries();
  const [selectedDelivery, setSelectedDelivery] = useState<IDelivery | null>(
    props.item
  );
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState<IDeliveryErrorInfo>();
  const { t } = useTranslation();

  const handleChangeDelivery = (delivery: IDelivery) => {
      setSelectedDelivery(delivery);
  };

  const handleEditConfirm = () => {
      if (!!selectedDelivery) {
          saveDelivery(selectedDelivery)
            .then(() => {
                invalidateDeliveries();
                updateDelivery();
                updateBasketDeliveryList();
                if (!!props.onUpdate) {
                    props.onUpdate()
                }
                setOpenModal(false);
            })
            .catch((err) => {
                setError(err as IDeliveryErrorInfo);
            });
      }
  };

  const handleEditCancel = () => {
    setOpenModal(false);
    setSelectedDelivery(null);
  }

  return (
    <div className={props.className}>
      <Button
        kind="secondary"
        onClick={() => setOpenModal(!openModal)}
        className=""
      >
        {t("delivery-edit")}
      </Button>
      <Modal
        open={openModal}
        onRequestClose={handleEditCancel}
        onRequestSubmit={handleEditConfirm}
        modalLabel={t("delivery-edit")}
        primaryButtonText={t('save')}
        secondaryButtonText={t('cancel')}
        width="full"
        lazyLoad
        children={
          <DeliveryForm
            item={props.item}
            onChange={handleChangeDelivery}
            error={error}
          />
        }
      />
    </div>
  );
}
