import { useNavigate } from "react-router-dom";
import { IUser } from '../../types/user';
import { ConfigurableTable, TableProps } from '../commons/configurable-table/configurable-table';

function UserTable(props: TableProps<IUser>) {
  const navigate = useNavigate();

  function handleRowClick(item: IUser) {
    navigate(`/users/${item.pk}/`);
  }

  return (
      <ConfigurableTable {...{
            ...props,
            onRowClick: handleRowClick,
            headers: ["Email", "Role"],
            fields: ["username", "role"],
            sortables: [
                { field: "username", code: "username", mode: 0, enabled: true },
                { field: "role", code: "role", mode: 0, enabled: true },
            ],
        }} />
  );
}

export default UserTable;
