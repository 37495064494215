import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BlockNotification, Form, RadioButton, Text } from "@wfp/react";
import { useGetBaskets } from "../../hooks/basket";
import Checkbox from "../commons/Checkbox";
import { IBulkError } from "./delivery-add";

interface Props {
  onChangeBaskets: (baskets: number[]) => void;
  onChangeBeneficiary: (radio: number) => void;
  baskets: number[];
  errors?: IBulkError;
}

function DeliveryBulkAddForm(props: Props) {
  const { t } = useTranslation();
  const { data: basket, isLoading, isError } = useGetBaskets({ pageNumber: 1 });
  const [checked, setChecked] = useState(1);

  const onCheckBasket = (id: number) => {
    if (props.baskets.includes(id)) {
      props.onChangeBaskets(props.baskets.filter((b) => b !== id));
    } else {
      props.onChangeBaskets([...props.baskets, id]);
    }
  };

  return (
    <Form>
      {props.errors?.generic && (
        <BlockNotification
          kind="error"
          title={t("Error")}
          subtitle={props.errors?.generic?.toString()}
        />
      )}
      <div className="flex">
        {!!basket && (
          <div className="wfp--form-item">
            <Text className="wfp--label">{t("basket-list")}</Text>
            {props.errors?.baskets && (
              <BlockNotification
                kind="error"
                title={t("Error")}
                subtitle={props.errors?.baskets?.toString()}
              />
            )}
            <div className="overflow-y-scroll max-h-60">
              {basket.results.map((b, idx) => (
                <div className="mr-10">
                  <Checkbox
                    name={b.name}
                    value={b.id}
                    onChange={(e) => {
                      onCheckBasket(e.target.value);
                    }}
                    key={idx}
                    labelText={b.name}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="wfp--form-item">
          <Text className="wfp--label">{t("Beneficiaries")}</Text>
          {props.errors?.beneficiary && (
              <BlockNotification
                kind="error"
                title={t("Error")}
                subtitle={props.errors?.beneficiary?.toString()}
              />
            )}
          <RadioButton
            name="type"
            value={checked}
            labelText={t("AllBeneficiaries")}
            defaultChecked
          />
        </div>
      </div>
    </Form>
  );
}

export default DeliveryBulkAddForm;
