import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IBeneficiary } from "../../types/beneficiary";
import { ConfigurableTable, TableProps } from '../commons/configurable-table/configurable-table';

function BeneficiaryTable(props: TableProps<IBeneficiary>) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleRowClick(item: IBeneficiary) {
    navigate(`/beneficiaries/${item.id}/`);
  }

  return (
        <ConfigurableTable {...{
          ...props,
          onRowClick: handleRowClick,
          headers: ["Email",  "IDMID", "Address"],
          fields: ["email",  "idmId", "address"],
          sortables: [
              { field: "email", code: "email", mode: 0, enabled: true },
              { field: "idmId", code: "idm_id", mode: 0, enabled: true },
              { field: "address", code: "address", mode: 0, enabled: false },
          ],
          formatters: {}
        }} />
  );
}

export default BeneficiaryTable;
