import { IBasketItem } from "../../types/basket";
import { ConfigurableTable, TableProps } from '../commons/configurable-table/configurable-table';

function BasketItemTable(props: TableProps<IBasketItem>) {
    return (
        <ConfigurableTable {...{
            ...props,
            deleteAction: (item: IBasketItem) => {
                if (props.onDeleteRow) {
                  props.onDeleteRow(item)
                }
              },
            editAction: (item: IBasketItem) => {
                if (props.onEditRow) {
                  props.onEditRow(item)
                }
              },
            headers: ["Name", "Quantity"],
            fields: ["name", "quantity"],
            sortables: [
                { field: "name", code: "name", mode: 0, enabled: true },
                { field: "quantity", code: "quantity", mode: 0, enabled: true },
            ]
        }} />
    )
}

export default BasketItemTable;
