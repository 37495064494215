import { IBasketDelivery } from '../../types/basket';
import { ConfigurableTable, TableProps } from '../commons/configurable-table/configurable-table';
import { formatDateTime } from "../../utils/format";

function DeliveryBasketTable(props: TableProps<IBasketDelivery>) {
  return (
      <ConfigurableTable {...{
            ...props,
            headers: ["Quantity", "Name", "CreatedOn", "Items"],
            fields: ["quantity", "name", "createdOn", "items"],
            sortables: [
                { field: "quantity", code: "quantity", mode: 0, enabled: true },
                { field: "name", code: "name", mode: 0, enabled: true },
                { field: "createdOn", code: "created_on", mode: 0, enabled: true },
                { field: "items", code: "items", mode: 0, enabled: false },
            ],
            formatters: {
              createdOn: (item: IBasketDelivery) => formatDateTime(item.basketInfo.createdOn),
              name: (item: IBasketDelivery) => item.basketInfo.name,
              items: (item: IBasketDelivery) => item.basketInfo.items.length.toString(),
            }
        }} />
  );
}

export default DeliveryBasketTable;
