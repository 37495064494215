import {ServerError} from "../types/commons";
import { IUser } from '../types/user';


export interface IUserError extends ServerError {
    role: string[] | undefined;
    username: string[] | undefined;
}

class UserErrorInfo implements IUserError {
    role: string[] | undefined;
    username: string[] | undefined;
    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}

export function validateUser(item: IUser): Promise<IUser> {
    const error = new UserErrorInfo();
    if (!item.username) {
        error.add('username', 'this_field_is_required');
    }
    if (!item.role) {
        error.add('role', 'this_field_is_required');
    }
    const isValid = Object.values(error).every(f => !f);
    return isValid ? Promise.resolve(item) : Promise.reject(error);
}
