import { IUser } from '../types/user';
import { formatQueryOptions, restapi } from './restapi';
import { KVPair, ListPage, PaginationParams } from '../types/commons';

export function getUserCurrent(): Promise<IUser> {
    return restapi.get<IUser>('/api-auth/v1/current/').then(r => r.data);
}

export function getUsers(params: PaginationParams, filters?: KVPair[], sorting?: string[]): Promise<ListPage<IUser>> {
    const qs = formatQueryOptions(params, filters, sorting);
    return restapi.get<ListPage<IUser>>(`/api-auth/v1/user/?${qs}&role_ex=beneficiary`).then((res) => ({...res.data, filtering: filters, sorting: sorting}));
}

export function getUserDetail(id: number): Promise<IUser> {
    return restapi.get<IUser>(`/api-auth/v1/user/${id}/`).then((res) => res.data);
}

export function saveUser(data: IUser): Promise<IUser> {
    data.email = data.username; // always overwrite email with username
    if (!data.pk) {
        return restapi.post<IUser>('/api-auth/v1/user/', data).then((res) => res.data);
    } else {
        return restapi.patch<IUser>(`/api-auth/v1/user/${data.pk}/`, data).then((res) => res.data);
    }
}

export function disableUser(id: number): Promise<IUser> {
    return restapi.post<IUser>(`/api-auth/v1/user/${id}/disable/`).then((res) => res.data);
}

