import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Role } from "../types/user";
import { useLoggedUser } from "../hooks/user";
import { LoadingCenter } from "../components/commons/loading-center";
import { Link } from "@wfp/react";
import { useTranslation } from "react-i18next";
import { Logo } from "../components/commons/logo";

export const Home = () => {
  const navigate = useNavigate();
  const user = useLoggedUser();
  const { t } = useTranslation();

  useEffect(() => {
    if (!!user && !!navigate) {
      if ([Role.Beneficiary].includes(user.role)) {
        navigate(`/mobile`);
      }
    }
  }, [user, navigate]);

  if (!user) {
    return <LoadingCenter />;
  }

  return (
    <div className="flex items-center justify-center ">
      <div className="flex flex-col">
        <Logo />
        {[Role.WFPManager, Role.WFPStaff].includes(user.role) && <div className="space-x-5 text-center">
          <Link href="/baskets" linkSolid visited={true}>
            {t("basket-list")}
          </Link>
          <Link href="/beneficiaries" linkSolid visited={true}>
            {t("Beneficiaries")}
          </Link>
          <Link href="/cycles" linkSolid visited={true}>
            {t("Cycles")}
          </Link>
          {user.role === Role.WFPManager && <Link href="/users" linkSolid visited={true}>
            {t("user-list")}
          </Link>}
        </div>
        }
      </div>
    </div>
  );
};
