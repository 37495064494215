import { useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbHome,
  BlockNotification,
  Button,
  Text,
  Modal,
  Loading,
} from "@wfp/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PaginationParams } from '../../types/commons';
import { DeliveryState } from "../../types/delivery";
import { useGetBeneficiary } from "../../hooks/beneficiary";
import {
  useConfirmDelivery,
  useGetBasketDeliveryList,
  useGetDelivery,
} from '../../hooks/delivery';
import { LoadingCenter } from "../commons/loading-center";
import BeneficiaryCard from "../beneficiary/beneficiary-card";
import DeliveryBasketTable from "./delivery-basket-table";
import DeliveryEdit from "./delivery-edit";

function DeliveryDetail() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [pagination, setPagination] = useState<PaginationParams>({
    pageNumber: 1,
  });
  const [sorting, setSorting] = useState<string[] | undefined>(["basket"]);

  const {
    data: delivery,
    isLoading,
    isError,
    refetch: refetchDelivery,
  } = useGetDelivery(Number(id));
  const {
    data: basketDeliveryList,
    isLoading: isLoadingBasketDeliveryList,
    isError: isErrorBasketDeliveryList,
    refetch: refetchBasketDeliveryList,
  } = useGetBasketDeliveryList(Number(id), !!delivery?.id, pagination, undefined, sorting);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const {
    mutate: confirmDelivery,
    isLoading: isLoadingConfirm,
    error: errorConfirm,
  } = useConfirmDelivery();
  const {
    data: beneficiary,
  } = useGetBeneficiary(Number(delivery?.beneficiary), !!delivery);

  const handleConfirmDelivery = (id: number) => {
    confirmDelivery(id);
    refetchDelivery().then(()=> {});
    setOpenConfirmModal(false);
  };

  return (
    <div>
      <Breadcrumb className="mt-5">
        <BreadcrumbItem>
          <a href="/#">
            <BreadcrumbHome />
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem href={'/cycles'}>{t("Cycles")}</BreadcrumbItem>
        <BreadcrumbItem href={`/cycles/${delivery?.cycle}`}>
          {!!delivery?.cycleName ? delivery.cycleName : ""}
        </BreadcrumbItem>
        <BreadcrumbItem disableLink>
          {!!beneficiary ? beneficiary.idmId : t("Delivery")}
        </BreadcrumbItem>
      </Breadcrumb>
      {isLoading && <LoadingCenter />}
      {isError && <div>{t("Error")}</div>}
      {!!errorConfirm && (
        <BlockNotification kind="error">{t("ErrorConfirm")}</BlockNotification>
      )}
      {!!delivery && (
        <div className="mt-8">
          <div className="text-justify">
            <div className="flex justify-between align-middle">
              <Text kind="story-title" className="text-2xl text-justify">
                {t("Details")}
              </Text>
              <div className="flex justify-end space-x-2">
                <DeliveryEdit item={delivery} onUpdate={() => refetchBasketDeliveryList().then(()=> {})}/>
                {delivery.state === DeliveryState.BOOKED && (
                  <Button
                    kind="primary"
                    onClick={() => setOpenConfirmModal(true)}
                    disabled={isLoadingConfirm}
                  >
                    {t("Confirm")}
                  </Button>
                )}
              </div>
            </div>
            <div className="w-2/5">
              <div className="flex justify-between mb-3">
                <span className="text-gray-500 ">{t("Code")}</span>
                <p> {delivery.code}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("DateFrom")}: </span>
                <p>{delivery.scheduledFrom}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("DateTo")}: </span>
                <p>{delivery.scheduledTo}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("State")}: </span>
                <p>{delivery.state}</p>
              </div>
            </div>
            <Text kind="story-title" className="text-2xl text-justify mt-8">
                {t("Beneficiary")}
              </Text>
            {!!beneficiary && 
            <div className="w-2/5">
            <BeneficiaryCard readOnly item={beneficiary} />
            </div>
            }
          </div>
          <div className="text-left mt-8">
            <div className="flex align-middle justify-between">
              <Text kind="story-title" className="text-2xl text-justify">
                {t("basket-list")}
              </Text>
            </div>
            {isLoadingBasketDeliveryList && <LoadingCenter />}
            {isErrorBasketDeliveryList && <div>{t("Error")}</div>}
            {!!basketDeliveryList && basketDeliveryList.count > 0 ? (
              <div className="flex-col">
                <DeliveryBasketTable
                  page={basketDeliveryList}
                  onChangePagination={setPagination}
                  onChangeSorting={setSorting}
                />
              </div>
            ) : (
              <BlockNotification
                hideCloseButton={false}
                kind="info"
                title={t("delivery-basket-missing-title")}
                subtitle={t("delivery-basket-missing-description")}
              />
            )}
          </div>
          {openConfirmModal && (
            <Modal
              open={true}
              onRequestSubmit={() => handleConfirmDelivery(delivery.id)}
              onRequestClose={() => setOpenConfirmModal(false)}
              modalHeading={t("Confirm")}
              primaryButtonText={t("Confirm")}
              secondaryButtonText={t('cancel')}
              width="full"
              children={
                <>
                  <Text>
                    {t("confirm-delivery-confirm")}
                  </Text>
                  {isLoadingConfirm && <Loading withOverlay={false} small />}
                </>
              }
            />
          )}
        </div>
      )}
    </div>
  );
}

export default DeliveryDetail;
