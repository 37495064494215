export interface IDelivery {
  beneficiary: string;
  code: string;
  beneficiaryEmail?: string;
  cycle: number;
  createdOn: string;
  cycleName: string;
  dataStarted: string;
  id: number;
  baskets: number[];
  state: string;
  scheduledFrom: string;
  scheduledTo: string;
}

export enum DeliveryState {
  BOOKED = "BOOKED",
  ASSIGNED = "ASSIGNED",
}
