import { useNavigate } from "react-router-dom";
import { IBasket } from '../../types/basket';
import { ConfigurableTable, TableProps } from '../commons/configurable-table/configurable-table';
import { formatDateTime } from "../../utils/format";

function BasketTable(props: TableProps<IBasket>) {
  const navigate = useNavigate();

  function handleRowClick(item: IBasket) {
    navigate(`/baskets/${item.id}/`);
  }

  return (
      <ConfigurableTable {...{
            ...props,
            onRowClick: handleRowClick,
            headers: ["ID", "Name", "CreatedOn"],
            fields: ["id", "name", "createdOn"],
            sortables: [
                { field: "id", code: "id", mode: 0, enabled: true },
                { field: "name", code: "name", mode: 0, enabled: true },
                { field: "createdOn", code: "created_on", mode: 0, enabled: true },
            ],
            formatters: {
              createdOn: (item: IBasket) => formatDateTime(item.createdOn),
            }
        }} />
  );
}

export default BasketTable;
